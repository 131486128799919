$(document).on('click', ".js-ajax-modal-dialog", function (event) {
    event.preventDefault();

    var eventElement = $(this);
    var url = $(this).data('url');
    var callbacks = $(this).data('callbacks');
    var cancelButton = getParameter($(this).data('cancelbutton'),'Cancel');
    var modalType = $(this).data('modal_type')
    if (modalType === undefined) {
        modalType = 'modal--confirm';
    }
    var modalSize = $(this).data('modal_size')
    if (modalSize === undefined) {
        modalSize = 'small'
    }

    var flashMessage = null;

    var ajaxCall = function () {
            $.ajax({
                url: url,
                method: 'GET',
                beforeSend: function () {

                        eventElement.block();

                },
                success: function (response) {
                    if (response.reload){
                        location.reload();
                    } else if (response.goToUrl) {
                        window.location.href = response.goToUrl;
                    } else if (response.success){

                        if (response.responseView){
                            openFoundationMessage(response.responseView, modalType, modalSize);
                        }

                        var methodsArr = [];
                        if (callbacks) {
                            methodsArr = callbacks.split(",");
                        }
                        for (var i = 0; i < methodsArr.length; i++) {
                            var func = window[methodsArr[i]];
                            var retCallback = func(response);
                        }

                    }
                    if (response.hasOwnProperty("flashMessage")){
                        flashMessage = response.flashMessage;
                    }


                    eventElement.addClass('active');
                },
                fail: function (xhr, textStatus, errorThrown) {
                    openFoundationMessage("An error has occurred , Please contact our office!");
                },
                complete: function () {

                        eventElement.unblock();

                    if (flashMessage) {
                        FlashMessage.showShortMessage(flashMessage);
                    }
                }
            });
        };

            ajaxCall();


});
